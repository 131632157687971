
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { Composicao } from '@/core/models/orcamentoObras';
import { ComposicaoService } from '@/core/services/orcamentoObras';

@Component  
export default class ListaComposicao extends mixins(Vue,ListPage) {
  public service =  new ComposicaoService();
  public item = new Composicao();
 
  loading: boolean = false;
  dialogCadastro: boolean = false;
  sheet:boolean = false;

  filter = {
    codigo:'',
    descricao:''
  } 

  titulo: string = 'Composição';
  subTitulo: string = 'Composições cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };   

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Código', value: 'codigo' },
    { text: 'Descrição', value: 'descricao' },
    { text: 'Origem', value: 'origem.nome' },
    { text: 'Ano', value: 'ano'},
    { text: 'Mes', value: 'mes'}
  ]; 

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search,this.headers, this.filter, '', '', 'Origem').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      }, 
      (err) => {  
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }).finally(() => (this.loading = false));
  }
 
  Novo(){
    this.item = new Composicao();
    this.dialogCadastro = true; 
  } 

  Editar(item){
    this.service.ObterPorId(item.id, 'Itens.ComposicaoAuxiliar.Unidade, Estado, Itens.Insumo.Unidade').then(
      (res) => {
        this.item = new Composicao(res.data);
        this.dialogCadastro = true; 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      } 
    )
  }

  Clonar(item){
    this.service.ObterPorId(item.id, 'Itens.ComposicaoAuxiliar.Unidade, Estado, Itens.Insumo.Unidade').then(
      (res) => {
        this.item = new Composicao();
        this.item.descricao = res.data.descricao;
        this.item.classeId = res.data.classeId;
        this.item.tipoId = res.data.tipoId;
        this.item.unidadeId = res.data.unidadeId;
        this.item.estado = res.data.estado;
        this.item.origemId = 1;
        this.dialogCadastro = true; 
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      } 
    )
  }

  mounted(){

  }
}
